<template>
  <button class="atc-form-close-button" @click="$emit('click')">
    <close-icon></close-icon>
  </button>
</template>

<script>
import CloseIcon from "@/assets/close.svg";
export default {
  components: {
    CloseIcon,
  },
};
</script>

<style lang="scss" scoped>
.atc-form-close-button {
  position: absolute;
  right: 6px;
  top: 6px;
  background: transparent;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  z-index: calc($index-icon-position + 1);
}
</style>
