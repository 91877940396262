<template>
  <div style="width: 100%">
    <button
      class="flex-button"
      :class="['atc-add-button', { 'inline-atc-add-button': !isFullScreen }]"
      @click="addToCart(ctaAction)"
      :disabled="isOutOfStock"
      :style="`border: 2px solid ${colors.primaryColor}; background: ${colors.primaryColor};`"
    >
      <shopping-bag
        :class="{ 'inline-icon': !isFullScreen }"
        ref="icon"
      ></shopping-bag>

      <span class="atc-add-button__text" :style="`color: ${secondaryColor}`"
        >{{ this.$t(`${buttonText}`) }}
      </span>
    </button>
  </div>
</template>

<script>
import ShoppingBag from "@/assets/shopping-bag.svg";
import enums from "../../enums";

const { ctaActions } = enums;

export default {
  components: {
    ShoppingBag,
  },
  props: {
    colors: {
      type: Object,
      default: () => {
        return { primaryColor: "#ffffff", secondaryColor: "#000000" };
      },
    },
    additionalData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    variantId: {
      type: [String, Number],
      default: null,
    },
    vidjetProductId: {
      type: String,
      default: "",
    },
    pluginProductId: {
      type: [String, Number],
      default: "",
    },
    isOutOfStock: {
      type: Boolean,
      default: true,
    },
    ctaAction: {
      type: String,
      default: ctaActions.addtoCart,
    },
    isManucurist: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ctaActions,
    };
  },

  mounted() {
    this.$refs.icon.setAttribute("fill", this.secondaryColor);
  },

  watch: {
    secondaryColor() {
      this.$refs.icon.setAttribute("fill", this.secondaryColor);
    },
  },

  computed: {
    primaryColor() {
      if (this.colors.primaryColor === this.colors.secondaryColor)
        return "#000000";
      return this.colors.primaryColor;
    },
    secondaryColor() {
      if (this.colors.primaryColor === this.colors.secondaryColor)
        return "#ffffff";
      return this.colors.secondaryColor;
    },
    buttonText() {
      return this.ctaAction === ctaActions.buyNow
        ? "player.atcFormAddButton.buyNow"
        : "player.atcFormAddButton.addToCart";
    },
  },

  methods: {
    addToCart(ctaAction) {
      const dataToSend = {
        messageOrigin: "vidjet",
        origin: "addToCart",
        properties: {
          productId: this.pluginProductId,
          variantId: this.variantId,
        },
      };

      if (Object.keys(this.additionalData).length > 0) {
        this.additionalData.properties.id = this.variantId;
        dataToSend.properties = {
          ...dataToSend.properties,
          ...this.additionalData,
        };
      }

      if (ctaAction === ctaActions.buyNow) {
        dataToSend.properties.isBuyNow = true;
      }

      window.parent.postMessage(dataToSend, "*");

      ctaAction === ctaActions.buyNow
        ? this.$emit("buy-now-clicked", {
            variantId: this.variantId,
            vidjetProductId: this.vidjetProductId,
            quantity: 1,
          })
        : this.$emit("added-to-cart", this.vidjetProductId);
    },
  },
};
</script>

<style lang="scss" scoped>
.atc-add-button {
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  background: white;
  transition: 0.4s ease box-shadow, 0.4s ease transform;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  margin: 0 auto;

  &:hover {
    box-shadow: none;
    transform: translateX(2px) translateY(2px);
  }

  &:disabled {
    background: grey;
    transform: translateX(2px) translateY(2px);
    cursor: not-allowed;
  }
}

.isFullScreen .atc-add-button {
  max-width: 100%;
}

.is-in-preview {
  width: 150px;
}

.flex-button {
  display: flex;
  gap: 4px;
  align-items: center;
}
.inline-atc-add-button {
  font-size: 14px;
}
.inline-icon {
  transform: scale(80%);
}
</style>
